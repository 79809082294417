import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import Select from "react-select";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setSaveAdminLog, setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey, DEFAULT_PAGE_SIZE, TransactionStatus, TransactionType } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { stringIsNullOrEmpty, numberWithCurrencyFormat2 } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AsyncPaginate } from "react-select-async-paginate";
import { useHistory } from "react-router-dom";
import POReactTable from "../../components/constantComponent/reactTable/POReactTable";

/// <summary>
/// Author : -
/// </summary>

const PaymentOfficerReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [startDate, setStartDate] = useState(moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"));
  const [endDate, setEndDate] = useState(moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"));
  const _SELECTED_USER_KEY = "selectedUserKey";
  const [username, setUsername] = useState("");
  const [initFinish, setInitFinish] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const [selectedUser, setSelectedUser] = useState("");
  const [label, setLabel] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [filterType, setFilterType] = useState(0);
  const [transactionType, setTransactionType] = useState("");
  const _STATUS_OPTIONS = [
    { value: "", label: 'All' },
    { value: TransactionStatus._APPROVED, label: 'Approved' },
    { value: TransactionStatus._REJECTED, label: 'Rejected' },
  ];

  const _DATE_OPTIONS = [
    { value: 0, label: 'Submit Time' },
    { value: 1, label: 'Audited Time' }
  ];

  const _TRANSACTION_OPTIONS = [
    { value: "", label: 'All' },
    { value: TransactionType._DEPOSIT, label: 'Deposit' },
    { value: TransactionType._WITHDRAWAL, label: 'Withdrawal' },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  const _defaultTableColumns = [

    {
      Header: t("TRANSACTION_ID"),
      accessor: "id",
    },
    {
      Header: t("TRANSACTION_DATE"),
      accessor: "submitTime",
      Cell: ({ row }) => {
        return <div className="text-align-right">{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</div>;
      },
    },
    {
      Header: t("TRANSACTION_TYPE"),
      accessor: "transactionTypeId",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.transactionTypeId === TransactionType._DEPOSIT) {
          return (<>
            <div className="text-align-right">{t("DEPOSIT")}</div>
          </>
          )
        }
        else if (row.original.transactionTypeId === TransactionType._WITHDRAWAL) {
          return (<>
            <div className="text-align-right">{t("WITHDRAWAL")}</div>
          </>
          )
        }
      },
    },
    {
      Header: t("AMOUNT"),
      accessor: "amount",
      minWidth: 100,
      Cell: ({ row }) => {
          return <div className="text-align-right">{numberWithCurrencyFormat2(row.original.amount.toFixed(2), 2, true)}</div>;
      },
    },
    {
      Header: t("STATUS"),
      accessor: "status",
      minWidth: 100,
      Cell: ({ row }) => {
        if (row.original.status === TransactionStatus._APPROVED) {
          return (<>
            <div className="display-flex flex-column">
              <span>{t("APPROVE")}</span>
            </div>
          </>)
        }
        else if (row.original.status === TransactionStatus._REJECTED) {
          return (<>
            <div className="display-flex flex-column">
              <span>{t("REJECT")}</span>
            </div>
          </>)
        }
      }
    },
    {
      Header: t("AUDITED_BY"),
      accessor: "auditedBy",
      minWidth: 100,
    },
    {
      Header: t("AUDITED_TIME"),
      accessor: "auditedTime",
      Cell: ({ row }) => {
        return <div className="text-align-right">{moment(row.original.auditedTime).format("YYYY-MM-DD HH:mm:ss")}</div>;
      },
    }
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (initFinish && props.id) {
      setUsername(props.username);
      searchBtnHandler();
    }
  }, [initFinish]);

  useEffect(() => {
    sessionStorage.setItem(_SELECTED_USER_KEY, selectedUser);
    if (selectedUser !== "All") {
      searchBtnHandler();
    }
  }, [selectedUser]);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    fetch(ApiUrl._API_GET_SYSTEM_DATETIME, {
      method: ApiKey._API_GET,
      headers: {
        'Content-Type': ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
      },
    }).then((response) => response.json()).then((responseJson) => {
      setCurrentDateTime(moment(responseJson[ApiKey._API_DATA_KEY]));
    }, []);
    setInitFinish(true);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async () => {
    let apiUrl =
      ApiUrl._API_GET_PAYMENT_OFFICER_REPORT +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&type=" +
      filterType +
      "&transactionType=" +
      transactionType

    if (selectedUser !== "") {
      apiUrl += "&userId=" + selectedUser;
    }
    if (!stringIsNullOrEmpty(selectedStatus)) {
      apiUrl += "&status=" + selectedStatus.value;
    }
    setSearchTrigger(apiUrl);
  };

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  async function adminLog() {
    let params = {
      "startDate": startDate,
      "endDate": endDate,
      "type": filterType
    };

    if (selectedUser !== "") {
      params["userId"] = selectedUser
    }
    if (!stringIsNullOrEmpty(username)) {
      params["username"] = username;
    }
    if (!stringIsNullOrEmpty(selectedStatus)) {
      params["status"] = selectedStatus.value;
    }

    _dispatch(setSaveAdminLog("VIEW_PAYMENT_OFFICER_REPORT", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("PAYMENT_OFFICER_REPORT")}
      </h1>
      <Panel
        style={{
          marginBottom: !stringIsNullOrEmpty(props.id) ? "0px" : "20px",
        }}
      >
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "70%" }}>
                        <Select
                          styles={_OPTION_STYLES}
                          defaultValue={_DATE_OPTIONS[0].value}
                          options={_DATE_OPTIONS}
                          onChange={(e) => {
                            setFilterType(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexFlow: "nowrap",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                          timePickerSeconds={true}
                          ranges={{
                            Today: [
                              moment().startOf("day"),
                              moment().endOf("day"),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").startOf("day"),
                              moment().subtract(1, "days").endOf("day"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-tag"></i>
                        </span>
                      </div>
                      <div style={{ width: "70%" }}>
                        <Select
                          styles={{
                            container: () => ({
                              width: "100%"
                            })
                          }}
                          value={selectedStatus}
                          options={_STATUS_OPTIONS}
                          onChange={(e) => {
                            setSelectedStatus(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-tag"></i>
                        </span>
                      </div>
                      <div style={{ width: "70%" }}>
                        <Select
                          styles={{
                            container: () => ({
                              width: "100%"
                            })
                          }}
                          defaultValue={_TRANSACTION_OPTIONS[0].value}
                          options={_TRANSACTION_OPTIONS}
                          onChange={(e) => {
                            setTransactionType(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group member-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <AsyncPaginate
                        placeholder={label}
                        debounceTimeout={250}
                        loadOptions={getMemberList}
                        additional={{
                          page: 1,
                        }}
                        value={selectedUser}
                        onChange={(e) => {
                          if (!stringIsNullOrEmpty(e.value)) {
                            sessionStorage.setItem(_SELECTED_USER_KEY, e.value);
                          } else {
                            sessionStorage.removeItem(_SELECTED_USER_KEY);
                          }
                          setLabel(e.label);
                          setSelectedUser(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      searchBtnHandler();
                      adminLog();
                    }}
                  >
                    <i className="fas fa-search"></i> {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: !stringIsNullOrEmpty(props.id) ? "0px 15px 0px 15px" : "",
            }}
          >
            <div className="col-lg-12">
              <POReactTable
                columns={_defaultTableColumns}
                fetchUrl={searchTrigger}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default PaymentOfficerReport;