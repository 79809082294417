import { fetch } from 'whatwg-fetch';
/// <summary>
/// Author : -
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || data == '';
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author : -
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    }
    else {
        history.push(url);
    }
}

/// <summary>
/// Author : -
/// Used to Navigate to previous path
/// </summary>
export function navigateBack(history) {
    history.goBack();
}

/// <summary>
/// Author : -
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    }
    else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

/// <summary>
/// Author : -
/// </summary>
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/// <summary>
/// Author : 
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2, force = false) {
    const CURRENCY_DECIMAL = 2;
    var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

    if (decimal > CURRENCY_DECIMAL) {
        let afterDecimalNumber = convertedNumber.split('.');
        let decimalDiff = decimal - CURRENCY_DECIMAL;
        if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
            let processNumber = afterDecimalNumber[1];
            let beginNumber = !force ? processNumber.length - 1 : processNumber;
            for (let i = beginNumber; (i > 0 && decimalDiff > 0); i--) {
                if (processNumber.charAt(i) == '0') {
                    processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
                }
                else {
                    break;
                }
                decimalDiff--;
            }
            convertedNumber = afterDecimalNumber[0] + "." + processNumber;
        }
    }

    return convertedNumber
}

//with comma
export function numberWithCurrencyFormat2(value, decimal = 2, force = false) {
    let isNegative = parseFloat(value) < 0;
    var valueStr = value.toString();

    // Handle negative numbers
    if (isNegative) { valueStr = valueStr.substring(1); }

    // Add decimal point if needed
    if (!valueStr.includes(".") && force) { valueStr += "."; }

    var finalValueStr = valueStr;

    // Handle decimal places
    if (valueStr.includes(".") || force) {
        let decimalPlace = valueStr.substring(valueStr.indexOf(".") + 1).length;
        if (decimalPlace < decimal) {
            while (decimalPlace < decimal) {
                finalValueStr += "0";
                decimalPlace++;
            }
        }
        finalValueStr = finalValueStr.substring(0, finalValueStr.indexOf(".") + decimal + 1);
    }

    // Handle the whole number part and add commas
    let finalWholeNumber = finalValueStr.split(".")[0]; // Get whole number part
    let decimalPart = finalValueStr.split(".")[1] || ""; // Get decimal part, if any

    if (finalWholeNumber.length > 3) {
        let wholeNumberStr = "";
        let digitsCount = finalWholeNumber.length;
        let remainder = digitsCount % 3;

        if (remainder > 0) {
            wholeNumberStr += finalWholeNumber.substring(0, remainder) + ",";
        }

        for (let i = remainder; i < digitsCount; i += 3) {
            wholeNumberStr += finalWholeNumber.substring(i, i + 3);
            if (i + 3 < digitsCount) {
                wholeNumberStr += ",";
            }
        }
        finalWholeNumber = wholeNumberStr;
    }

    finalValueStr = finalWholeNumber + (decimalPart ? "." + decimalPart : "");

    // Handle negative numbers
    if (isNegative) { finalValueStr = "-" + finalValueStr; }

    return finalValueStr;
}

/// <summary>
/// Author : -
/// </summary>
export async function imagePathToFileObject(imagePath) {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], imagePath);
    return file;
}

/// <summary>
/// Author: -
/// </summary>
export function isObjectEmpty(data) {
    return Object.keys(data).length === 0;
}