import React, { useState } from "react";
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { numberWithCurrencyFormat2 } from "../../util/Util";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSaveAdminLog } from "../../redux/AppAction";

const TotalSharePayoutReport = () => {
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    var _dispatch = useDispatch();
    let _history = useHistory();
    const [initPage, setInitPage] = useState(true);
    const [reportData, setReportData] = useState([]);
    const [startDate, setStartDate] = useState(moment().add(-1, "days").startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().add(-1, "days").endOf('day').format("YYYY-MM-DD"));

    const _TABLE_COLUMNS = [
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "NetProfit",
            accessor: "netProfit",
            Cell: ({ row }) => (
                <div className={row.original.netProfit < 0 ? "text-red" : ""} style={{ textAlign: "right" }}>{numberWithCurrencyFormat2(row.original.netProfit)}</div>
            ),
        }
    ]

    const getPayoutReport = async () => {
        let apiUrl = ApiUrl._API_GET_TOTAL_SHARE_PAYOUT_REPORT + "?startDate=" + startDate + "&endDate=" + endDate;
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setReportData(data["data"]);
        }
        setInitPage(false);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
    };

    async function adminLog() {
      let params = {
        "startDate": startDate,
        "endDate": endDate
      }
      _dispatch(setSaveAdminLog("VIEW_TOTAL_SHARE_PAYOUT_REPORT", _history.location.pathname, params));
      
    }

    return (
        <div>
            <h1 className="page-header">{t("TOTAL_SHARE_PAYOUT_REPORT")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div
                                        className="form-group"
                                        style={{ display: "flex", width: "100%" }}
                                    >
                                        <div className="input-group" style={{ width: "100%" }}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    alwaysShowCalendars={true}
                                                    onApply={handleEvent}
                                                    maxDate={moment().endOf('day')}
                                                    ranges={{
                                                        'Today': [moment().startOf('day'), moment().endOf('day')],
                                                        'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                        'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                        'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        'This Year': [moment().startOf('year'), moment().endOf('year')]
                                                    }}>
                                                    <input type="text" className="form-control" value={startDate + " - " + endDate} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {getPayoutReport(); adminLog()}}
                                    >
                                        {t("SEARCH")}
                                    </button>
                                </div>
                            </div>
                            <div className="total-share-payout">
                                <ReactTable data={reportData} columns={_TABLE_COLUMNS} />
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default TotalSharePayoutReport;